import firebase from "firebase/compat/app";

export default async function getUserPlan() {
  await firebase.auth().currentUser.getIdToken(true);
  const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
  switch (decodedToken.claims.stripeRole) {
    case 'premium':
      return 'Apprentice';
    case 'master':
      return 'Master Wizard';
    default:
      return 'Novice';
  }
}
